import { ViewId } from '@schema-common/base';
import { IconButton } from './IconButton';
import { useCopyViewUrl } from './useCopyViewUrl';
import { faEllipsisH, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { viewTitleBarAtom } from '../viewTitlaBarAtom';
import { useAtomValue } from 'jotai';
import { ThemeColor } from '@view-model/models/common/color';
import { classNames } from '@framework/utils';

type Props = {
    readonly: boolean;
    viewId: ViewId;
    onCopyView(): void;
    onOpenPopupMenu(): void;
    viewThemeColor: ThemeColor;
};

export const menuButtonGap = 20;
// 右上メニュー要素の最大アイコン数 (この個数のアイコンを配置可能な幅が確保される)
export const maxIconCount = 3;

export const ViewTitleMenu: React.FC<Props> = ({
    readonly,
    viewId,
    onCopyView,
    onOpenPopupMenu,
    viewThemeColor,
}: Props) => {
    const handleCopyViewUrl = useCopyViewUrl(viewId);

    const {
        menu: { buttonSize, fontSize, containerWidth },
    } = useAtomValue(viewTitleBarAtom);

    return (
        <div
            className={classNames(
                'flex shrink-0 flex-row flex-nowrap items-center justify-end',
                viewThemeColor === ThemeColor.White ? 'text-white' : ''
            )}
            style={{
                gap: menuButtonGap,
                width: containerWidth,
            }}
        >
            {/* ※ アイコンボタンの数が増減する場合には、 maxIconCount の値も合わせて変更すること。 */}
            <IconButton
                icon={faLink}
                tooltip="リンクをコピー | Copy link"
                onClick={handleCopyViewUrl}
                fontSize={fontSize}
                buttonSize={buttonSize}
                viewThemeColor={viewThemeColor}
            />
            <IconButton
                icon={faCopy}
                tooltip="ビューをコピー | Copy view"
                onClick={onCopyView}
                fontSize={fontSize}
                buttonSize={buttonSize}
                viewThemeColor={viewThemeColor}
            />
            {!readonly && (
                <IconButton
                    icon={faEllipsisH}
                    onClick={(event) => {
                        event.stopPropagation();
                        onOpenPopupMenu();
                    }}
                    fontSize={fontSize}
                    buttonSize={buttonSize}
                    viewThemeColor={viewThemeColor}
                />
            )}
        </div>
    );
};
