import { DragContext, DraggableSVGGElement } from '@model-framework/ui';
import { ViewId, ViewModelId } from '@schema-common/base';
import { ViewName } from '@view-model/domain/view';
import { Rect } from '@view-model/models/common/basic';
import { useD3DblClickCallback } from '@view-model/models/common/hooks';
import { useCallback, useRef, useState } from 'react';
import { BaseTitleBar } from './BaseTitleBar';
import { ThemeColor } from '@view-model/models/common/color';

type Props = {
    viewRect: Rect;
    name: ViewName;
    viewId: ViewId;
    viewModelId: ViewModelId;
    isLocked: boolean;
    onClick: () => void;
    onCopyView: () => void;
    onOpenPopupMenu: () => void;
    onDragStart: (viewId: ViewId) => void;
    onDrag: (context: DragContext) => void;
    onDragEnd: () => void;
    width: number;
    height: number;
    margin: number;
    menuContainerWidth: number;
    shouldHideMenuButtons: boolean;
    viewThemeColor: ThemeColor;
};

export const DraggableTitleBar = ({
    viewRect,
    name,
    viewId,
    viewModelId,
    isLocked,
    onClick,
    onCopyView,
    onOpenPopupMenu,
    onDragStart,
    onDrag,
    onDragEnd,
    width,
    height,
    margin,
    menuContainerWidth,
    shouldHideMenuButtons,
    viewThemeColor,
}: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const ref = useRef<SVGRectElement | null>(null);

    const startEdit = useCallback(() => setIsEditing(true), []);
    useD3DblClickCallback(ref, startEdit, !isLocked);

    const handleOnNameEndEdit = useCallback(() => setIsEditing(false), []);
    const handleDragStart = useCallback(() => onDragStart(viewId), [onDragStart, viewId]);

    return (
        <DraggableSVGGElement
            position={viewRect.topLeft()}
            onDragStart={handleDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onClick={onClick}
        >
            <BaseTitleBar
                ref={ref}
                width={width}
                height={height}
                margin={margin}
                menuContainerWidth={menuContainerWidth}
                shouldHideMenuButtons={shouldHideMenuButtons}
                viewThemeColor={viewThemeColor}
                name={name}
                viewId={viewId}
                viewModelId={viewModelId}
                isLocked={isLocked}
                isEditing={isEditing}
                onNameEndEdit={handleOnNameEndEdit}
                onCopyView={onCopyView}
                onOpenPopupMenu={onOpenPopupMenu}
            />
        </DraggableSVGGElement>
    );
};
