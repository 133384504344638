import { StickyZone } from './StickyZone';
import { StickyZoneFontSize, StickyZoneStyleSet } from './StickyZoneStyle';
import { StickyZoneKey } from '@view-model/domain/key';
import { Id } from '@framework/domain';
import { ThemeColor } from '@view-model/models/common/color';
import { CommandHelper, CompositeCommand, ICommand } from '@model-framework/command';
import { Rect } from '@view-model/models/common/basic';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { RectShape, ShapeMap } from '@model-framework/shape';
import { UserKey } from '@user/domain';
import { StickyZoneJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/sticky-zones/{stickyZoneKey}/StickyZoneJSON';
import { ModelId, StickyZoneId, ViewModelId } from '@schema-common/base';
import { RTDBPath } from '@framework/repository';

export class StickyZoneCollection {
    readonly models: StickyZone[];

    constructor(models: StickyZone[] = []) {
        this.models = [...new Set(models)];
    }

    static buildEmpty(): StickyZoneCollection {
        return new StickyZoneCollection([]);
    }

    get length(): number {
        return this.models.length;
    }

    static load(dump: StickyZoneJSON[]): StickyZoneCollection {
        return new this(dump.map((j) => StickyZone.load(j)));
    }

    dump(): StickyZoneJSON[] {
        return this.models.map((zone) => zone.dump());
    }

    cloneNew(): [StickyZoneCollection, Record<string, StickyZoneKey>, Record<string, string>] {
        const newZones: StickyZone[] = [];
        // コピー元のZoneのKeyと新しいZoneのKeyのマッピング
        const keyMap: Record<string, StickyZoneKey> = {};
        // コピー元のZoneのidと新しいZoneのidのマッピング
        const idMap: Record<string, string> = {};

        this.models.forEach((zone) => {
            const newZone = zone.cloneNew();
            keyMap[zone.key.toString()] = newZone.key;
            idMap[zone.id] = newZone.id;
            newZones.push(newZone);
        });
        return [new StickyZoneCollection(newZones), keyMap, idMap];
    }

    isEqual(other: StickyZoneCollection): boolean {
        if (!(other instanceof StickyZoneCollection)) return false;
        if (this.length !== other.length) return false;

        return this.models.every((zone) => {
            const otherZone = other.findByKey(zone.key);
            return otherZone && zone.isEqual(otherZone);
        });
    }

    public entities(): StickyZone[] {
        return this.models.concat();
    }

    filter(cb: (zone: StickyZone) => boolean): StickyZoneCollection {
        return new StickyZoneCollection(this.models.filter(cb));
    }

    findByKey(key: StickyZoneKey): StickyZone | undefined {
        return this.models.find((zone) => zone.key.isEqual(key));
    }

    findById(zoneId: StickyZoneId): StickyZone | undefined {
        return this.models.find((zone) => zone.id == zoneId);
    }

    map<T>(fn: (value: StickyZone, index: number) => T): T[] {
        return this.models.map(fn);
    }

    forEach(fn: (value: StickyZone, index: number) => void): void {
        return this.models.forEach(fn);
    }

    includeKey(key: StickyZoneKey): boolean {
        return this.models.some((zone) => zone.key.isEqual(key));
    }

    filterByIds(ids: Id[]): StickyZoneCollection {
        const models = this.models.filter(({ id }) => ids.includes(id));
        return new StickyZoneCollection(models);
    }

    keys(): StickyZoneKey[] {
        return this.models.map(({ key }) => key);
    }

    ids(): StickyZoneId[] {
        return this.models.map(({ id }) => id);
    }

    add(zone: StickyZone): StickyZoneCollection {
        return this.addZones([zone]);
    }

    addZones(zones: StickyZone[]): StickyZoneCollection {
        const restZones = zones.filter((zone) => !this.includeKey(zone.key));
        const newZones: Record<string, StickyZone> = {};
        zones.forEach((zone) => (newZones[zone.key.toString()] = zone));

        const models = this.models.map((zone) => newZones[zone.key.toString()] || zone).concat(...restZones);
        return new StickyZoneCollection(models);
    }

    remove(key: StickyZoneKey): StickyZoneCollection {
        return this.removeKeys([key]);
    }

    removeKeys(keys: StickyZoneKey[]): StickyZoneCollection {
        const models = this.models.filter((zone) => !keys.some((k) => k.isEqual(zone.key)));
        return new StickyZoneCollection(models);
    }

    /**
     * 自身のコレクションを引数で指定された models で置き換えます
     *
     * @param models
     */
    replace(models: StickyZone[]): void {
        this.models.splice(0, this.models.length, ...models);
    }

    themeColors(): ThemeColor[] {
        return this.models.map((zone) => zone.style.themeColor);
    }

    fontSizes(): StickyZoneFontSize[] {
        return this.models.map((zone) => zone.style.fontSize);
    }

    styleSet(): StickyZoneStyleSet {
        return StickyZoneStyleSet.fromZones(this.models);
    }

    isEmpty(): boolean {
        return this.models.length === 0;
    }

    // Zone, ZonePosition の削除コマンドは作られるが DisplayOrder, Linkは消えないのでそのまま使うのは危険
    async buildDeleteCommand(viewModelId: ViewModelId, modelId: ModelId): Promise<ICommand | null> {
        const commands: Promise<ICommand | null>[] = [];

        for (const zone of this.models) {
            commands.push(
                CommandHelper.buildDeleteCommand(RTDBPath.Zone.zonePath(viewModelId, modelId, zone.key)),
                CommandHelper.buildDeleteCommand(RTDBPath.Zone.positionPath(viewModelId, modelId, zone.id)),
                CommandHelper.buildDeleteCommand(RTDBPath.Zone.descriptionPath(viewModelId, modelId, zone.id))
            );
        }

        return CompositeCommand.composeOptionalCommands(...(await Promise.all(commands)));
    }

    /**
     * コレクションを囲む矩形を返します。
     */
    getBounds(positions: PositionSet): Rect | null {
        if (this.isEmpty()) return null;

        const bounds = this.models
            .map((zone) => {
                const position = positions.find(zone.id);
                return position ? zone.getRect(position) : null;
            })
            .filter((bound): bound is Rect => !!bound);

        return Rect.union(bounds);
    }

    shapeMap(): ShapeMap<RectShape> {
        const entries = this.models.map((zone) => {
            return {
                id: zone.id,
                shape: zone.getShape(),
            };
        });

        return new ShapeMap(entries);
    }

    /**
     * IDとゾーンエンティティのRecordに変換します。
     */
    toRecord(): Record<StickyZoneId, StickyZone> {
        const record: Record<StickyZoneId, StickyZone> = {};
        this.models.forEach((zone) => {
            record[zone.id] = zone;
        });
        return record;
    }

    withCreatedUserKey(userKey: UserKey): StickyZoneCollection {
        return new StickyZoneCollection(this.models.map((zone) => zone.withCreatedUserKey(userKey)));
    }
}
