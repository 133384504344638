// eslint-disable-next-line no-restricted-imports
import dayjs, { extend, locale } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ja';
import { UnixTimestampMilliSeconds, TimestampISOString } from '@schema-common/timestamp';

extend(utc);
extend(timezone);
extend(relativeTime);
locale('ja');

type TimestampValue = TimestampISOString | UnixTimestampMilliSeconds;

export class Timestamp {
    private readonly value: dayjs.Dayjs;

    constructor(value: TimestampValue | dayjs.Dayjs) {
        this.value = dayjs(value);
    }

    static now(): Timestamp {
        return new Timestamp(dayjs());
    }

    static fromISOString(dump: string): Timestamp {
        return new Timestamp(dump);
    }

    static fromUnixTimestamp(dump: number): Timestamp {
        return new Timestamp(dump);
    }

    toUnixTimestamp(): UnixTimestampMilliSeconds {
        return this.value.valueOf();
    }

    /**
     * ISO 8601 形式の文字列表現に変換します。
     */
    toISOString(): string {
        return this.value.toISOString();
    }

    format(template?: string, timezone?: string): string {
        return this.value.tz(timezone).format(template);
    }

    toString(): string {
        return this.value.toString();
    }

    compareTo(other: Timestamp): number {
        return this.value.valueOf() - other.value.valueOf();
    }

    isValid(): boolean {
        return this.value.isValid();
    }

    isEqual(other: Timestamp): boolean {
        return this.value.isSame(other.value);
    }

    static compare(a: Timestamp, b: Timestamp): number {
        return a.compareTo(b);
    }

    addSeconds(value: number): Timestamp {
        return new Timestamp(this.value.add(value, 'seconds'));
    }

    addMilliSeconds(value: number): Timestamp {
        return new Timestamp(this.value.add(value, 'milliseconds'));
    }

    addMinutes(value: number): Timestamp {
        return new Timestamp(this.value.add(value, 'minutes'));
    }

    addDays(value: number): Timestamp {
        return new Timestamp(this.value.add(value, 'days'));
    }

    addMonths(value: number): Timestamp {
        return new Timestamp(this.value.add(value, 'months'));
    }

    toDate(): Date {
        return this.value.toDate();
    }

    valueOf(): number {
        return this.value.valueOf();
    }

    isAfterNow(): boolean {
        return this.value.isAfter(dayjs());
    }

    isBeforeNow(): boolean {
        return this.value.isBefore(dayjs());
    }

    isAfter(other: Timestamp): boolean {
        return this.value.isAfter(other.value);
    }

    diffSeconds(other: Timestamp): number {
        return this.value.diff(other.value, 'seconds');
    }

    diffMilliSeconds(other: Timestamp): number {
        return this.value.diff(other.value, 'milliseconds');
    }

    fromNow(): string {
        return this.value.fromNow();
    }

    startOf(unit: dayjs.UnitType): Timestamp {
        return new Timestamp(this.value.startOf(unit));
    }

    endOf(unit: dayjs.UnitType): Timestamp {
        return new Timestamp(this.value.endOf(unit));
    }
}
