import { ViewName } from '@view-model/domain/view';
import { useAtomValue } from 'jotai';
import { viewTitleBarAtom } from '../viewTitlaBarAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { ThemeColor } from '@view-model/models/common/color';
import { classNames } from '@framework/utils';

const PLACE_HOLDER = 'タイトル | Title';

type Props = {
    name: ViewName;
    isLocked?: boolean;
    viewThemeColor: ThemeColor;
};

export const ReadonlyViewTitle: React.FC<Props> = ({ name, isLocked = false, viewThemeColor }: Props) => {
    const {
        title: { fontSize },
    } = useAtomValue(viewTitleBarAtom);

    return (
        <div
            className={classNames(
                'pointer-events-none grow truncate text-center font-bold',
                viewThemeColor === ThemeColor.White ? 'text-white' : ''
            )}
            style={{ fontSize }}
        >
            {isLocked && <FontAwesomeIcon icon={faLock} className="pr-4" />}
            {name.value ? name.value : PLACE_HOLDER}
        </div>
    );
};
