import { forwardRef } from 'react';
import { ThemeColor } from '@view-model/models/common/color';
import { ViewColor } from '../ViewStyle';
import { ViewName } from '@view-model/domain/view';
import { ViewId, ViewModelId } from '@schema-common/base';
import { ReadonlyViewTitle } from '../ViewTitleInput/ReadonlyViewTitle';
import { ViewTitleMenu } from '../ViewTitleMenu';
import { EditableViewTitle } from '../ViewTitleInput/EditableViewTitle';

type Props = {
    width: number;
    height: number;
    margin: number;
    menuContainerWidth: number;
    shouldHideMenuButtons: boolean;
    viewThemeColor: ThemeColor;
    name: ViewName;
    viewId: ViewId;
    viewModelId?: ViewModelId;
    isLocked?: boolean;
    isEditing?: boolean;
    readonly?: boolean;
    onNameEndEdit?: () => void;
    onCopyView: () => void;
    onOpenPopupMenu: () => void;
};

export const BaseTitleBar = forwardRef<SVGRectElement, Props>(
    (
        {
            width,
            height,
            margin,
            menuContainerWidth,
            shouldHideMenuButtons,
            viewThemeColor,
            name,
            viewId,
            viewModelId,
            isLocked = false,
            isEditing = false,
            readonly = false,
            onNameEndEdit,
            onCopyView,
            onOpenPopupMenu,
        },
        ref
    ) => {
        const isReadonly = readonly || isLocked || !isEditing;

        return (
            <>
                <rect
                    ref={ref}
                    width={width}
                    height={height}
                    rx={height / 2}
                    ry={height / 2}
                    x={0}
                    y={-height - margin * 2}
                    stroke="none"
                    cursor="move"
                    fill={ViewColor.getTitleBarColor(viewThemeColor)}
                />
                <foreignObject
                    width={width}
                    height={height}
                    transform={`translate(0, ${-height - margin * 2})`}
                    className="pointer-events-none"
                >
                    <div className={'flex items-center gap-8 px-16 text-[80px]'} style={{ height }}>
                        {!shouldHideMenuButtons && <div className="shrink-0" style={{ width: menuContainerWidth }} />}
                        {isReadonly ? (
                            <ReadonlyViewTitle name={name} isLocked={isLocked} viewThemeColor={viewThemeColor} />
                        ) : (
                            <EditableViewTitle
                                viewModelId={viewModelId!}
                                viewId={viewId}
                                isEditing={isEditing}
                                name={name}
                                onNameEndEdit={onNameEndEdit!}
                                viewThemeColor={viewThemeColor}
                            />
                        )}
                        {!shouldHideMenuButtons && (
                            <ViewTitleMenu
                                readonly={readonly}
                                viewId={viewId}
                                onCopyView={onCopyView}
                                onOpenPopupMenu={onOpenPopupMenu}
                                viewThemeColor={viewThemeColor}
                            />
                        )}
                    </div>
                </foreignObject>
            </>
        );
    }
);

BaseTitleBar.displayName = 'BaseTitleBar';
