import { getClientId } from '@framework/app';
import { useHandler, useMousetrap } from '@framework/hooks';
import { ViewModelOperationLogSender } from '@model-framework/action-log';
import { CommandManager } from '@model-framework/command';
import { LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';
import { GroupId, ModelCommentThreadId, NodeId, StickyZoneId, ViewId } from '@schema-common/base';
import { UserPublicProfile } from '@user/PublicProfile';
import { ElementDragManager, StickyModelContentsOperation } from '@view-model/adapter';
import { ApplicationClipboardPayload } from '@view-model/application/clipboard';
import { MLAPIPayload } from '@view-model/application/ml-api/MLAPIPayload';
import { WindowEventManager, KeyboardShortcuts } from '@view-model/application/shortcuts';
import { LinkKey, LinkableTargetKey, ModelElementId, StickyZoneKey } from '@view-model/domain/key';
import { StickyModel, StickyModelElement, StickyModelElementCollection } from '@view-model/domain/model';
import { ViewEntity } from '@view-model/domain/view';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { Point, Rect, Size, SizeSet } from '@view-model/models/common/basic';
import { ThemeColor } from '@view-model/models/common/color';
import { LinkerCanvasView } from '@view-model/models/common/components/LinkCreator';
import { DescriptionPanelCollectionView } from '@view-model/models/sticky/DescriptionPanel';
import {
    CreatingModelComment,
    CreatingModelCommentWrapper,
    ModelCommentList,
} from '@view-model/models/sticky/ModelComment';
import { LinkerOperation, LinkEntity, LinkPlacementFactory, StickyLink } from '@view-model/models/sticky/StickyLink';
import { ElementDescriptionView } from '@view-model/models/sticky/ElementDescription';
import { NodeCollection, NodeFontSize, StickyNode, StickyNodeView } from '@view-model/models/sticky/StickyNodeView';
import {
    StickyZone,
    StickyZoneView,
    StickyZoneContextProvider,
    StickyZoneCollection,
} from '@view-model/models/sticky/StickyZoneView';
import { MultiSelectionView } from '@view-model/models/sticky/ui';
import { ModelCanvas, RectSelectorView } from '@view-model/ui/components/Model';
import { Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import { IStickyModelContentsViewHandlers } from './IStickyModelContentsViewHandlers';
import { NullHandlers } from './NullHandlers';
import { ReadonlyHandlers } from './ReadonlyHandlers';
import { WritableHandlers } from './WritableHandlers';
import { LinkerState } from '@view-model/models/common/components/LinkCreator/LinkerState';
import { MultiSelectionMode } from '@user/pages/ViewModelPage';
import { EditingUserRepository } from '@model-framework/text/editing-user';
import { useSelectedItems } from '../../../../models/sticky/client-selected-items/useSelectedItems';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { DragContext } from '@model-framework/ui';
import { AlignType } from '@view-model/models/sticky/ui/MultiSelectionView/components';
import { StickyModelContentsLoader } from '@view-model/ui/components/Model/StickyModelContentsView/StickyModelContentsLoader';
import { useAtomValue } from 'jotai';
import { stickyModelContentsAtomFamily } from '@view-model/adapter/stickyModelContentsAtomFamily';
import { ElementDescriptionTarget } from '@view-model/models/sticky/ElementDescription/domain/ElementDescriptionTarget';

type Position = {
    x: number;
    y: number;
};

type SizeType = {
    width: number;
    height: number;
};

type Props = {
    readonly: boolean;
    ownerGroupId: GroupId;
    currentUserProfile: UserPublicProfile;
    viewModel: ViewModelEntity;
    view: ViewEntity;
    model: StickyModel;
    isSelected: boolean;
    showNodeCreatedUser: boolean;
    canvasSize: SizeType;
    onSelectSingleView(viewId?: ViewId): void;
    onToggleSelectedViews(): void;
    onDeselectAllViews(): void;
    setMultiSelectionMode(multiSelectionMode: MultiSelectionMode): void;
    onCopyView(): void;
    windowEventManager: WindowEventManager;
    multiSelectionMode: MultiSelectionMode;
    commandManager: CommandManager;
    logSender: ViewModelOperationLogSender;
    onDeleteView(): void;
    onClickViewModelLink(e: React.MouseEvent): void;
    modelCommentThreadId: string | null;
    operation: StickyModelContentsOperation;
    creatingComment: CreatingModelComment | undefined;
    onCreatingCommentAdd(): void;
    onCreatingCommentDrag(dx: number, dy: number): void;
    onCreatingCommentDragEnd(): void;
    onCreatingCommentCancel(): void;
    onCreatingCommentSubmit(creatingComment: CreatingModelComment): void;
    onZoneToView(sourceView: ViewEntity, operation: StickyModelContentsOperation): void;
    onAnalysisStart(): void;
    onAnalysisSuccess(payload: MLAPIPayload): void;
    onAnalysisFailure(): void;
    getTransformedVisibleAreaCenterPoint: (viewRect: Rect) => Point;
    viewRect: Rect;
    getViewRectOperations(): Record<
        ViewId,
        { view: ViewEntity; rect?: Rect; operation?: StickyModelContentsOperation }
    >;
};

type State = {
    linkerState: LinkerState<LinkableTargetKey> | null;
    startEditTargetId: NodeId | null;
    selectionRect: Rect | null;
    dropTargetZone: StickyZone | null;
    commentSizeSet: SizeSet;
    replacingLinkKey: LinkKey | null;
};

const StickyModelContentsViewFn = (props: Props) => {
    const propsRef = useRef<Props>(props);
    propsRef.current = props;
    const { onSelectSingleView, modelCommentThreadId, view, model, onDeselectAllViews } = props;

    const [state, setState] = useState<State>({
        linkerState: null,
        startEditTargetId: null,
        dropTargetZone: null,
        selectionRect: null,
        commentSizeSet: new SizeSet(),
        replacingLinkKey: null,
    });

    const selectedItems = useSelectedItems({ clientId: getClientId(), modelId: model.id });

    const stickyModelContents = useAtomValue(stickyModelContentsAtomFamily(model.id));
    const { panelContents, commentContents, displayOrderTree, stickyZonePositions, stickyZones, stickyNodes, links } =
        stickyModelContents;

    const nodePositions = new NodeCollection(stickyNodes).positionSet();

    const shapeMap = useMemo(
        () => new NodeCollection(stickyNodes).shapeMap().merge(new StickyZoneCollection(stickyZones).shapeMap()),
        [stickyNodes, stickyZones]
    );

    const editingUserRepositoryRef = useRef<EditingUserRepository>(new EditingUserRepository(props.viewModel.id));

    useMousetrap(
        KeyboardShortcuts.escape,
        () => {
            if (state.linkerState || state.replacingLinkKey) {
                setState((prev) => {
                    if (prev.replacingLinkKey) {
                        editingUserRepositoryRef.current.delete(prev.replacingLinkKey).then();
                    }
                    return { ...prev, linkerState: null, replacingLinkKey: null };
                });
            }

            handlersRef.current.handleAllElementsDeselect();
            onDeselectAllViews();
        },
        'keydown'
    );

    useEffect(() => {
        const unsubscribe = props.windowEventManager.listenOnWindowActive((windowActive: boolean) => {
            if (!windowActive) {
                setState((prev) => {
                    if (prev.replacingLinkKey) {
                        editingUserRepositoryRef.current.delete(prev.replacingLinkKey).then();
                    }
                    if (prev.linkerState || prev.replacingLinkKey) {
                        return { ...prev, linkerState: null, replacingLinkKey: null };
                    }
                    return prev;
                });
            }
        });

        return () => {
            unsubscribe();
        };
    }, [props.windowEventManager]);

    const handlersRef = useRef<IStickyModelContentsViewHandlers>(new NullHandlers());

    // コメントリストからジャンプしてきた時の対応
    useEffect(() => {
        if (modelCommentThreadId) {
            propsRef.current.operation.selectOnly(modelCommentThreadId);
            onSelectSingleView();
        }
    }, [onSelectSingleView, modelCommentThreadId, view.id]);

    const dragManagerRef = useRef<ElementDragManager | null>(null);

    if (!dragManagerRef.current) {
        dragManagerRef.current = new ElementDragManager(
            props.viewModel.id,
            props.model.id,
            props.model.key,
            props.view.id,
            props.commandManager,
            props.operation,
            props.getViewRectOperations,
            props.onSelectSingleView
        );
    }

    useEffect(() => {
        if (!props.isSelected) {
            handlersRef.current = new NullHandlers();
            return;
        }

        if (props.readonly) {
            const { operation } = propsRef.current;
            handlersRef.current = new ReadonlyHandlers(operation);
        } else {
            const { onDeleteView, operation } = propsRef.current;
            handlersRef.current = new WritableHandlers(operation, onDeleteView);
        }
    }, [props.isSelected, props.readonly]);

    useEffect(() => {
        // 離脱する際には選択を解除する
        const operation = props.operation;
        return () => {
            operation.deleteMySelection();
        };
    }, [props.operation]);

    const multiSelectedRect = selectedItems.isMultiSelected()
        ? props.model.getBoundsOf(selectedItems.getSelectedItems())
        : null;

    const handleStartEditNode = useHandler(() => {
        const selectedIds = selectedItems.getSelectedItems();
        // 単一のNodeが選択されているときのみ実行
        if (selectedIds.length !== 1) {
            return;
        }
        setState((prev) => ({ ...prev, startEditTargetId: selectedIds[0] }));
    });

    // 矢印の方向にあるノードに選択を移動する
    const handleMoveSelection = useHandler((direction: 'up' | 'left' | 'right' | 'down') => {
        // ビュー選択モードの場合は何もしない
        if (props.multiSelectionMode.isMultiViewsSelectionMode) {
            return;
        }

        // 単一のNodeが選択されているときのみ実行
        const selectedIds = selectedItems.getSelectedItems();
        if (selectedIds.length !== 1) {
            return;
        }

        // 現在選択しているノードとその位置を得る
        const selectedNode = stickyNodes.find((node) => {
            return node.id === selectedIds[0];
        });

        // ノードが見つからない、もしくは選択している要素がノードじゃない場合は何もしない
        if (!selectedNode) {
            return;
        }

        // 範囲外のノードを除外
        const targetNodes = stickyNodes.filter(
            (() => {
                switch (direction) {
                    case 'up':
                        return (n: StickyNode) => n.position.y < selectedNode.position.y;
                    case 'down':
                        return (n: StickyNode) => n.position.y > selectedNode.position.y;
                    case 'left':
                        return (n: StickyNode) => n.position.x < selectedNode.position.x;
                    case 'right':
                        return (n: StickyNode) => n.position.x > selectedNode.position.x;
                }
            })()
        );

        const minFactor = Math.tan(Math.PI * (20 / 180)); // 一定の角度以内であれば距離の重み付けは均一にする
        const sortedNodes = targetNodes
            .map((node) => {
                const xDiff = Math.abs(node.position.x - selectedNode.position.x);
                const yDiff = Math.abs(node.position.y - selectedNode.position.y);
                const distance = selectedNode.position.toPoint().distance(node.position.toPoint());

                // 相対的な位置から重み付けの値を計算する
                const factor = Math.max(
                    minFactor,
                    ['up', 'down'].includes(direction) ? xDiff / distance : yDiff / distance
                );

                return {
                    node,
                    // 実際の距離ではなく概念的な距離を計算する
                    distance: factor * distance,
                };
            })
            .sort((left, right) => {
                if (left.distance === right.distance) {
                    // 距離が同じ場合は、結果が不定にならないようにする
                    return left.node.compareByDisplayOrder(right.node);
                }
                return left.distance < right.distance ? -1 : 1;
            });

        if (sortedNodes[0]) {
            // 距離の一番近いノードを探して選択する
            props.operation.selectOnly(sortedNodes[0].node.id);
        }
    });

    useMousetrap(
        KeyboardShortcuts.delete,
        () => {
            // ドラッグ中であればショートカットによる削除操作を受け付けない
            if (draggingRef.current) return;
            handlersRef.current.handleDeleteSelectedElements();
        },
        'keydown'
    );

    useMousetrap(
        KeyboardShortcuts.up,
        (e) => {
            const { height: nodeHeight } = StickyNode.size();

            if (e.ctrlKey || e.metaKey) {
                handleMoveSelection('up');
            } else {
                handlersRef.current.handleArrowKeys(0, -nodeHeight / 4);
            }
        },
        'keydown'
    );

    useMousetrap(
        KeyboardShortcuts.down,
        (e) => {
            const { height: nodeHeight } = StickyNode.size();

            if (e.ctrlKey || e.metaKey) {
                handleMoveSelection('down');
            } else {
                handlersRef.current.handleArrowKeys(0, nodeHeight / 4);
            }
        },
        'keydown'
    );

    useMousetrap(
        KeyboardShortcuts.left,
        (e) => {
            const { height: nodeWidth } = StickyNode.size();

            if (e.ctrlKey || e.metaKey) {
                handleMoveSelection('left');
            } else {
                handlersRef.current.handleArrowKeys(-nodeWidth / 4, 0);
            }
        },
        'keydown'
    );

    useMousetrap(
        KeyboardShortcuts.right,
        (e) => {
            const { height: nodeWidth } = StickyNode.size();

            if (e.ctrlKey || e.metaKey) {
                handleMoveSelection('right');
            } else {
                handlersRef.current.handleArrowKeys(nodeWidth / 4, 0);
            }
        },
        'keydown'
    );

    useMousetrap(
        KeyboardShortcuts.selectAll,
        () => {
            handlersRef.current.handleAllElementsSelect();
        },
        'keydown'
    );

    useMousetrap(
        KeyboardShortcuts.enter,
        () => {
            handleStartEditNode();
        },
        'keydown'
    );

    // タブキーで選択中のノードの右にノードを追加する
    useMousetrap(
        KeyboardShortcuts.tab,
        () => {
            if (props.multiSelectionMode.isMultiViewsSelectionMode || props.readonly) {
                return;
            }

            const selectedIds = selectedItems.getSelectedItems();
            if (selectedIds.length !== 1) {
                return;
            }

            const selectedNode = stickyNodes.find((node) => {
                return node.id === selectedIds[0];
            });

            if (!selectedNode) {
                return;
            }

            const nodeSize = StickyNode.size();

            const createNodePosition = (i: number): Position => {
                const mx = ModelLayout.GridSize * 2;
                const my = ModelLayout.GridSize * 2;
                const position = { x: selectedNode.position.x + mx + nodeSize.width, y: selectedNode.position.y };

                if (i > 0) {
                    if (i % 2 === 1) {
                        position.y -= (Math.floor(i / 2) + 1) * (my + nodeSize.height);
                    } else {
                        position.y += Math.floor(i / 2) * (my + nodeSize.height);
                    }
                }

                return position;
            };

            // 選択中のノードの右側にあるノードだけ考慮
            const targetNodes = stickyNodes.filter((node) => node.position.x > selectedNode.position.x);
            const nodePosition = (() => {
                for (let i = 0; ; i++) {
                    const position = createNodePosition(i);

                    const isIn = (targetPosition: Position) => {
                        return (
                            targetPosition.x <= position.x + nodeSize.width &&
                            targetPosition.x >= position.x &&
                            targetPosition.y <= position.y + nodeSize.height &&
                            targetPosition.y >= position.y
                        );
                    };

                    // すでにあるノードと位置がかぶる場合は別の場所を探す
                    if (
                        targetNodes.some(
                            (node) =>
                                isIn(node.position) ||
                                isIn(node.position.add(nodeSize.width, 0)) ||
                                isIn(node.position.add(0, nodeSize.height)) ||
                                isIn(node.position.add(nodeSize.width, nodeSize.height))
                        )
                    ) {
                        continue;
                    }

                    return position;
                }
            })();

            const [node, link] = props.operation.createLinkedNode(Point.fromPosition(nodePosition), selectedNode.key);
            setState((prev) => ({ ...prev, startEditTargetId: node.id })); // 対象要素をテキスト編集状態にする

            props.logSender('sticky:node:create', {
                nodeId: node.id,
                cause: 'tab',
            });

            props.logSender('sticky:link:create', {
                nodeId: link.id,
                cause: 'tab',
            });
        },
        'keydown'
    );

    const handlePasteClipboard = useHandler(async (payload: ApplicationClipboardPayload) => {
        const { getTransformedVisibleAreaCenterPoint, viewRect, operation } = props;
        const createPoint = operation.getCreatePoint(getTransformedVisibleAreaCenterPoint(viewRect));
        handlersRef.current.handlePasteClipboardPayload(payload, createPoint);
    });

    const handleDuplicateSelectedElements = useHandler(async () => {
        const { getTransformedVisibleAreaCenterPoint, viewRect, operation } = props;
        const createPoint = operation.getCreatePoint(getTransformedVisibleAreaCenterPoint(viewRect));
        handlersRef.current.handleDuplicateSelectedElements(createPoint);
    });

    useEffect(() => {
        const windowEventManager = props.windowEventManager;
        const modelKey = props.model.key;

        windowEventManager.onModelContentsMounted(modelKey, {
            onCopy: () => handlersRef.current.handleCopySelectedElements(),
            onCut: () => {
                // ドラッグ中であればショートカットによる切り取り操作を受け付けない
                if (draggingRef.current) return;
                handlersRef.current.handleCutSelectedElements();
            },
            onPaste: handlePasteClipboard,
            onDuplicate: () => handleDuplicateSelectedElements(),
        });

        return () => {
            windowEventManager.onModelContentsWillUnmount(modelKey);
        };
    }, [props.windowEventManager, props.model.key, handlePasteClipboard, handleDuplicateSelectedElements]);

    useEffect(() => {
        if (!props.isSelected && selectedItems.isSomeSelected()) {
            props.operation.deselectAll();
            setState((prev) => {
                if (prev.replacingLinkKey) {
                    editingUserRepositoryRef.current.delete(prev.replacingLinkKey).then();
                }
                return { ...prev, linkerState: null, replacingLinkKey: null };
            });
        }
    }, [props.isSelected, props.operation, selectedItems]);

    const handleClickCanvas = useHandler(() => {
        const {
            isSelected: isViewSelected,
            onSelectSingleView,
            operation,
            multiSelectionMode,
            setMultiSelectionMode,
            onToggleSelectedViews,
        } = props;

        // ビューが選択されていて、かつ、モデル要素が選択されている状態でキャンバスがクリックされたときには、
        // モデル要素の選択状態を解除、リンク作成モードを解除する
        if (isViewSelected && selectedItems.isSomeSelected()) {
            operation.deselectAll();
            setState((prev) => {
                if (prev.replacingLinkKey) {
                    editingUserRepositoryRef.current.delete(prev.replacingLinkKey).then();
                }
                return { ...prev, linkerState: null, replacingLinkKey: null };
            });
            return;
        }

        // ビューが選択されていない場合はビューを選択状態にする
        if (!isViewSelected) {
            if (multiSelectionMode.isReadyForMultiSelection) {
                onToggleSelectedViews();
                setMultiSelectionMode(MultiSelectionMode.multiViewsSelectionMode);
            } else if (multiSelectionMode.isMultiViewsSelectionMode) {
                onToggleSelectedViews();
            } else {
                onSelectSingleView();
            }
        } else {
            if (multiSelectionMode.isReadyForMultiSelection || multiSelectionMode.isMultiViewsSelectionMode) {
                onToggleSelectedViews();
            } else {
                onSelectSingleView();
            }
        }
    });

    const handleDelete = useHandler(() => {
        handlersRef.current.handleDeleteSelectedElements();
    });

    const handleCreateNodeByDblClick = useHandler((position: Position) => {
        if (props.readonly) return;

        props.onSelectSingleView(); // ビューを選択して
        const nodeId = props.operation.createNodeAtClickedPosition(Point.fromPosition(position));
        setState((prev) => ({ ...prev, startEditTargetId: nodeId })); // 対象要素をテキスト編集状態にする

        props.logSender('sticky:node:create', {
            nodeId,
            cause: 'dblclick_canvas',
        });
    });

    const handleElementClick = useHandler((id: ModelElementId) => {
        const { multiSelectionMode, operation, onSelectSingleView, onToggleSelectedViews, setMultiSelectionMode } =
            props;
        // Shiftキーが押されていれば選択状態をトグルし、そうでなければ単一選択状態に変更する
        if (multiSelectionMode.isReadyForMultiSelection) {
            operation.toggleSelection(id);
            onSelectSingleView();
            setMultiSelectionMode(MultiSelectionMode.multiElementsSelectionMode);
        } else if (multiSelectionMode.isMultiElementsSelectionMode) {
            operation.toggleSelection(id);
        } else if (multiSelectionMode.isMultiViewsSelectionMode) {
            onToggleSelectedViews();
        } else {
            operation.selectOnly(id);
            onSelectSingleView();
        }
    });

    const handleAddNodeToZone = useHandler((position: Position, zoneKey: StickyZoneKey) => {
        if (props.readonly) return;
        props.onSelectSingleView(); // ビューを選択して

        const nodeId = props.operation.createNodeToZone(Point.fromPosition(position), zoneKey);

        setState((prev) => ({ ...prev, startEditTargetId: nodeId })); // 対象要素をテキスト編集状態にする

        props.logSender('sticky:node:create', {
            nodeId,
            zoneId: zoneKey.id,
            cause: 'dblclick_zone',
        });
    });

    const handleNameEditStartedOnMount = useHandler(() => {
        setState((prev) => ({ ...prev, startEditTargetId: null }));
    });

    const handleUpdateCommentSize = useHandler((id: ModelCommentThreadId, newSize: Size) => {
        setState((prev) => ({
            ...prev,
            commentSizeSet: prev.commentSizeSet.set(id, newSize),
        }));
    });

    const draggingRef = useRef<boolean>(false);
    const handleDragStart = useHandler((id: ModelElementId) => {
        const { multiSelectionMode, onSelectSingleView, onToggleSelectedViews } = props;

        if (props.readonly) {
            return;
        }

        if (multiSelectionMode.isMultiViewsSelectionMode) {
            onToggleSelectedViews();
            return;
        }

        draggingRef.current = true;
        onSelectSingleView();
        dragManagerRef.current?.onDragStart(id, multiSelectionMode);

        const dropTargetZone = props.operation.findDropTargetZone();
        setState((prev) => ({ ...prev, dropTargetZone }));
    });

    const handleDrag = useHandler((context: DragContext) => {
        if (props.multiSelectionMode.isMultiViewsSelectionMode || props.readonly) {
            return;
        }

        if (draggingRef.current) {
            dragManagerRef.current?.onDrag(context);
            const dropTargetZone = props.operation.findDropTargetZone();
            setState((prev) => ({ ...prev, dropTargetZone }));
        }
    });

    const handleDragEnd = useHandler((context: DragContext) => {
        if (props.multiSelectionMode.isMultiViewsSelectionMode || props.readonly) {
            return;
        }

        if (draggingRef.current) {
            dragManagerRef.current?.onDragEnd(context);
            draggingRef.current = false;
        }

        setState((prev) => ({ ...prev, dropTargetZone: null }));
    });

    const handleEditStart = useHandler((id: ModelElementId) => {
        if (props.readonly) return;

        props.operation.selectOnly(id);
    });

    const handleRectSelection = useHandler((selectionRect: Rect) => {
        if (!props.isSelected) {
            // 矩形選択しようとしているビューが非選択状態ならば、選択状態に変更して
            props.onSelectSingleView();
        }

        setState((prev) => ({ ...prev, selectionRect }));
    });

    const handleRectSelectionEnd = useHandler((selectionRect: Rect, zoneId?: StickyZoneId) => {
        const { operation } = props;
        operation.selectElementsByRect({ selectionRect, zoneId, commentSizeSet: state.commentSizeSet });

        setState((prev) => ({ ...prev, selectionRect: null }));
    });

    const handleCreateLinkerStart = useHandler((key: LinkableTargetKey, startPosition: Position) => {
        const linkerState = LinkerState.creatingStart<LinkableTargetKey>(key, startPosition);
        setState((prev) => ({ ...prev, linkerState }));
    });

    const handleReplaceLinkerStart = useHandler((linkerState: LinkerState<LinkableTargetKey>, linkKey: LinkKey) => {
        setState((prev) => ({ ...prev, linkerState, replacingLinkKey: linkKey }));
    });

    const handleLinkerMove = useHandler((currentPosition: Position) => {
        const linkableElement = props.operation.findForegroundElementByPosition(currentPosition);

        if (!state.linkerState) {
            return;
        }

        const elementKey = LinkerOperation.getLinkableElementKey({
            linkerState: state.linkerState,
            linkableElement,
            displayOrderTree,
        });
        const linkerState = state.linkerState.move(elementKey, currentPosition);
        setState((prev) => ({ ...prev, linkerState }));
    });

    const handleLinkerEnd = useHandler((currentPosition: Position) => {
        const linkableElement = props.operation.findForegroundElementByPosition(currentPosition);

        if (!state.linkerState) {
            console.warn(
                'LinkerOperation.handleLinkerFinished() is called multiple times after single handleLinkStart(). ' +
                    'It may cause an unpredictable problem.'
            );
            return;
        }

        const targetKey = LinkerOperation.getLinkableElementKey({
            linkableElement,
            displayOrderTree,
            linkerState: state.linkerState,
        });
        const linkerState = state.linkerState.move(targetKey, currentPosition);

        if (!propsRef.current.readonly) {
            if (linkerState.source && linkerState.target) {
                if (linkerState.isCreating()) {
                    const linkKey = propsRef.current.operation.createLink(linkerState.source, linkerState.target);

                    if (!linkKey) {
                        return;
                    }

                    propsRef.current.logSender('sticky:link:create', {
                        linkId: linkKey.id,
                        cause: 'linker',
                    });
                } else {
                    const { replacingLinkKey } = state;
                    if (!replacingLinkKey) return;

                    const currentLink = links.find((link) => link.key.isEqual(replacingLinkKey));
                    if (!currentLink) return;

                    propsRef.current.operation.replaceLink(
                        currentLink.key,
                        { sourceKey: currentLink.from, targetKey: currentLink.to },
                        { sourceKey: linkerState.source, targetKey: linkerState.target }
                    );
                }
            }
        }

        setState((prev) => ({ ...prev, replacingLinkKey: null, linkerState: null }));
    });

    const handleNodeZoneThemeColorMenuSelected = useHandler((themeColor: ThemeColor) => {
        props.operation.changeSelectedNodeZoneThemeColor(themeColor);
    });

    const handleNodeZoneFontSizeMenuSelected = useHandler((fontSize: NodeFontSize) => {
        props.operation.changeSelectedNodeZoneFontSize(fontSize);
    });

    const handleMultiSelectGroupSelectedElements = useHandler(async () => {
        const zoneId = await props.operation.groupSelectedElements();
        props.logSender('sticky:zone:create', {
            zoneId,
            cause: 'grouping',
        });
    });

    const handleLinkLineStyleMenuSelected = useHandler((lineStyle: LinkLineStyle) => {
        props.operation.changeSelectedLinkLineStyles(lineStyle);
    });

    const handleLinkMarkStyleMenuSelected = useHandler((markStyle: LinkMarkStyle) => {
        props.operation.changeSelectedLinkMarkStyles(markStyle);
    });

    const handleReverseLinks = useHandler(() => {
        props.operation.changeReverseLinks();
    });

    const handleRemoveMultiSelectedLinks = useHandler(() => {
        props.operation.removeMultiSelectedLinks();
    });

    const handleLinkColorMenuSelected = useHandler((linkColor: LinkColor) => {
        props.operation.changeSelectedLinkColors(linkColor);
    });

    const handleAlignSelected = useHandler((alignType: AlignType, interval: number) => {
        props.operation.alignSelectedNodes(alignType, interval);
    });

    const elementCollection = new StickyModelElementCollection(stickyNodes, links, stickyZones, stickyZonePositions);
    const orderedElements = elementCollection.backToFront(displayOrderTree, selectedItems);
    const linkPlacementFactory = new LinkPlacementFactory(nodePositions, stickyZonePositions, shapeMap);

    const handleZoneToView = useHandler(() => {
        props.onZoneToView(props.view, props.operation);
    });

    return (
        <>
            <StickyModelContentsLoader model={props.model} viewModelId={props.viewModel.id} />
            <g>
                <ModelCanvas
                    viewId={props.view.id}
                    size={props.canvasSize}
                    onClickCanvas={handleClickCanvas}
                    onDblClickCanvas={handleCreateNodeByDblClick}
                    onRectSelection={handleRectSelection}
                    onRectSelectionEnd={handleRectSelectionEnd}
                    isMultiSelectionMode={
                        props.multiSelectionMode.isReadyForMultiSelection ||
                        props.multiSelectionMode.isMultiElementsSelectionMode
                    }
                    setMultiSelectionMode={props.setMultiSelectionMode}
                    onSelectSingleView={onSelectSingleView}
                    viewThemeColor={view.themeColor}
                />

                {/* 説明パネルの一覧 */}
                <DescriptionPanelCollectionView
                    viewModelId={props.viewModel.id}
                    modelId={props.model.id}
                    panelContents={panelContents}
                    selectedItems={selectedItems}
                    readonly={props.readonly}
                    onClick={handleElementClick}
                    onDragStart={handleDragStart}
                    onDrag={handleDrag}
                    onDragEnd={handleDragEnd}
                />

                {orderedElements.map((element: StickyModelElement) => {
                    if (element instanceof StickyZone) {
                        const zone = element;
                        const position = stickyZonePositions.find(zone.id);
                        const shape = shapeMap.get(zone.id);
                        if (!position || !shape) return null;

                        const isSelected = selectedItems.isSelected(zone.id);
                        const isMultiSelected = isSelected && selectedItems.isMultiSelected();
                        const isLinkableTarget =
                            !!state.linkerState?.target?.isEqual(zone.key) ||
                            !!state.linkerState?.source?.isEqual(zone.key);
                        const showMenu = !props.readonly && isSelected && selectedItems.isSingleSelected();

                        return (
                            <StickyZoneContextProvider
                                key={zone.key.toString()}
                                currentUserProfile={props.currentUserProfile}
                                viewModelId={props.viewModel.id}
                                modelId={props.model.id}
                                zoneId={zone.id}
                                operation={props.operation}
                            >
                                <ElementDescriptionView
                                    viewModelId={props.viewModel.id}
                                    modelId={props.model.id}
                                    target={ElementDescriptionTarget.Zone}
                                    elementId={zone.id}
                                    elementRect={zone.getRect(position)}
                                    readonly={props.readonly}
                                />
                                <StickyZoneView
                                    key={zone.key.toString()}
                                    viewModelId={props.viewModel.id}
                                    view={props.view}
                                    stickyZone={zone}
                                    position={position}
                                    ownerGroupId={props.ownerGroupId}
                                    currentUserProfile={props.currentUserProfile}
                                    readonly={props.readonly}
                                    isHoverSelected={!!state.dropTargetZone?.key.isEqual(zone.key)}
                                    isSelected={isSelected}
                                    isMultiSelected={isMultiSelected}
                                    isLinkableTarget={isLinkableTarget}
                                    isMultiSelectionMode={
                                        props.multiSelectionMode.isReadyForMultiSelection ||
                                        props.multiSelectionMode.isMultiElementsSelectionMode
                                    }
                                    shape={shape}
                                    showMenu={showMenu}
                                    showCreatedUser={props.showNodeCreatedUser}
                                    onClick={handleElementClick}
                                    onDragStart={handleDragStart}
                                    onDrag={handleDrag}
                                    onDragEnd={handleDragEnd}
                                    onEditStart={handleEditStart}
                                    onCreateNodeInZone={handleAddNodeToZone}
                                    onLinkerStart={handleCreateLinkerStart}
                                    onLinkerMove={handleLinkerMove}
                                    onLinkerEnd={handleLinkerEnd}
                                    onDelete={handleDelete}
                                    onGroupSelectedZone={handleMultiSelectGroupSelectedElements}
                                    onZoneToView={handleZoneToView}
                                    onFontSizeSelected={handleNodeZoneFontSizeMenuSelected}
                                    onThemeColorSelected={handleNodeZoneThemeColorMenuSelected}
                                    onRectSelection={handleRectSelection}
                                    onRectSelectionEnd={handleRectSelectionEnd}
                                    onAnalysisStart={props.onAnalysisStart}
                                    onAnalysisSuccess={props.onAnalysisSuccess}
                                    onAnalysisFailure={props.onAnalysisFailure}
                                    logSender={props.logSender}
                                />
                            </StickyZoneContextProvider>
                        );
                    }

                    if (element instanceof StickyNode) {
                        const node = element;

                        // positions に座標が含まれていない場合には、描画をスキップする
                        // (positions が変化したときには再描画されるので、ここでは単純にスキップすれば良い)
                        const position = nodePositions.find(node.id);
                        const nodeShape = shapeMap.get(node.id);
                        if (!position || !nodeShape) return null;

                        const isSelected = selectedItems.isSelected(node.id);
                        const showMenu = isSelected && selectedItems.isSingleSelected();
                        const isMultiSelected = isSelected && selectedItems.isMultiSelected();
                        const isLinkableTarget =
                            !!state.linkerState?.target?.isEqual(node.key) ||
                            !!state.linkerState?.source?.isEqual(node.key);
                        const baseColor = elementCollection.getNodeBaseColor(node.id, displayOrderTree);

                        return (
                            <Fragment key={node.key.toString()}>
                                <ElementDescriptionView
                                    viewModelId={props.viewModel.id}
                                    modelId={props.model.id}
                                    target={ElementDescriptionTarget.Node}
                                    elementId={node.id}
                                    elementRect={node.getRect()}
                                    readonly={props.readonly}
                                />
                                <StickyNodeView
                                    key={node.key.toString()}
                                    readonly={props.readonly}
                                    viewModelId={props.viewModel.id}
                                    view={props.view}
                                    modelId={props.model.id}
                                    node={node}
                                    position={position}
                                    ownerGroupId={props.ownerGroupId}
                                    currentUserProfile={props.currentUserProfile}
                                    nodeShape={nodeShape}
                                    isSelected={isSelected}
                                    baseColor={baseColor}
                                    showMenu={showMenu}
                                    isMultiSelected={isMultiSelected}
                                    isLinkableTarget={isLinkableTarget}
                                    logSender={props.logSender}
                                    showNodeCreatedUser={props.showNodeCreatedUser}
                                    onClick={handleElementClick}
                                    onDragStart={handleDragStart}
                                    onDrag={handleDrag}
                                    onDragEnd={handleDragEnd}
                                    onEditStart={handleEditStart}
                                    startEditOnMount={state.startEditTargetId === node.id}
                                    onNameEditStartedOnMount={handleNameEditStartedOnMount}
                                    onLinkerStart={handleCreateLinkerStart}
                                    onLinkerMove={handleLinkerMove}
                                    onLinkerEnd={handleLinkerEnd}
                                    onClickViewModelLink={props.onClickViewModelLink}
                                    onGroupSelectedNode={handleMultiSelectGroupSelectedElements}
                                    onDelete={handleDelete}
                                    onFontSizeSelected={handleNodeZoneFontSizeMenuSelected}
                                    onThemeColorSelected={handleNodeZoneThemeColorMenuSelected}
                                    onAnalysisStart={props.onAnalysisStart}
                                    onAnalysisSuccess={props.onAnalysisSuccess}
                                    onAnalysisFailure={props.onAnalysisFailure}
                                />
                            </Fragment>
                        );
                    }

                    if (element instanceof LinkEntity) {
                        const link = element;
                        const isSelected = selectedItems.isSelected(link.id);
                        // リンクが1つだけ選択されている場合は操作メニュー付きとして描画する。
                        const showMenu = isSelected && selectedItems.isSingleSelected();

                        const placement = linkPlacementFactory.linkPlacementOf(link);
                        if (!placement) return null;

                        // リンクの接続されているいずれかのノードが選択されているか否か
                        const isRelatedNodeSelected =
                            selectedItems.isSelected(link.fromId) || selectedItems.isSelected(link.toId);

                        const isReplacing = state.replacingLinkKey ? link.key.isEqual(state.replacingLinkKey) : false;
                        return (
                            <StickyLink
                                key={link.key.toString()}
                                readonly={props.readonly}
                                viewModel={props.viewModel}
                                modelKey={props.model.key}
                                link={link}
                                isSelected={isSelected}
                                isRelatedNodeSelected={isRelatedNodeSelected}
                                placement={placement}
                                showMenu={showMenu}
                                onClick={handleElementClick}
                                onLineStyleSelected={handleLinkLineStyleMenuSelected}
                                onMarkStyleSelected={handleLinkMarkStyleMenuSelected}
                                onColorSelected={handleLinkColorMenuSelected}
                                onReplaceStart={handleReplaceLinkerStart}
                                onReplaceMove={handleLinkerMove}
                                onReplaceEnd={handleLinkerEnd}
                                isReplacing={isReplacing}
                            />
                        );
                    }
                })}
                {/* 複数要素を選択しているときに表示する外接する矩形領域 */}
                {multiSelectedRect && (
                    <MultiSelectionView
                        rect={multiSelectedRect}
                        readonly={props.readonly}
                        isNodeSelected={elementCollection.isNodeSelected(selectedItems)}
                        isMultipleNodeSelected={elementCollection.isMultipleNodeSelected(selectedItems)}
                        isLinkSelected={elementCollection.isLinkSelected(selectedItems)}
                        isZoneSelected={elementCollection.isZoneSelected(selectedItems)}
                        currentFontSize={elementCollection.getSelectedFontSize(selectedItems)}
                        currentThemeColor={elementCollection.getSelectedThemeColor(selectedItems)}
                        currentLinkLineStyle={elementCollection.getSelectedLinkLineStyle(selectedItems)}
                        currentLinkMarkStyle={elementCollection.getSelectedLinkMarkStyle(selectedItems)}
                        currentLinkColor={elementCollection.getSelectedLinkColor(selectedItems)}
                        onFontSizeSelected={handleNodeZoneFontSizeMenuSelected}
                        onThemeColorSelected={handleNodeZoneThemeColorMenuSelected}
                        onGroupSelectedElements={handleMultiSelectGroupSelectedElements}
                        onLinkLineStyleSelected={handleLinkLineStyleMenuSelected}
                        onLinkMarkStyleSelected={handleLinkMarkStyleMenuSelected}
                        onReverseLinks={handleReverseLinks}
                        onRemoveLinks={handleRemoveMultiSelectedLinks}
                        onLinkColorSelected={handleLinkColorMenuSelected}
                        onAlignSelected={handleAlignSelected}
                    />
                )}

                {/* リンク作成中には一番手前に透明なキャンバスを重ねる */}
                {(state.linkerState?.source || state.linkerState?.target) && (
                    <LinkerCanvasView
                        canvasSize={props.canvasSize}
                        linkerState={state.linkerState}
                        onLinkerMove={handleLinkerMove}
                        onLinkerEnd={handleLinkerEnd}
                        viewModelId={props.viewModel.id}
                        replacingLinkKey={state.replacingLinkKey}
                    />
                )}

                {/* モデルコメントの一覧 */}
                <ModelCommentList
                    viewModelId={props.viewModel.id}
                    modelId={props.model.id}
                    commentContents={commentContents}
                    onUpdateCommentSize={handleUpdateCommentSize}
                    selectedItems={selectedItems}
                    readonly={props.readonly}
                    onDragStart={handleDragStart}
                    onDrag={handleDrag}
                    onDragEnd={handleDragEnd}
                    onClick={handleElementClick}
                />

                {/* 下書き途中(未投稿)のモデルコメント */}
                <CreatingModelCommentWrapper
                    comment={props.creatingComment}
                    onUpdateCommentPosition={props.onCreatingCommentDrag}
                    onUpdateCommentPositionDragEnd={props.onCreatingCommentDragEnd}
                    onCancel={props.onCreatingCommentCancel}
                    onSubmit={props.onCreatingCommentSubmit}
                />

                {/* Shiftキー + ドラッグで選択中の矩形領域 */}
                {state.selectionRect && <RectSelectorView rect={state.selectionRect} />}
            </g>
        </>
    );
};

export const StickyModelContentsView = memo(StickyModelContentsViewFn);
