import { GroupEntity, GroupLicense, GroupMemberSignUpMethod } from '@group/domain';
import { Button } from '@framework/ui/atoms/Button';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { generateDefaultIcon } from '@framework/utils';
import { AvailableSignUpMethodForm } from './AvailableSignUpMethodForm';
import { AnalysisSettingForm } from './AnalysisSettingForm';
import { GroupAnalysisSetting } from '@group/domain/GroupEntity';
import { GroupLicenseInfo } from './GroupLicenseInfo';
import { RTDBPath, usePersistedObject } from '@framework/repository';
import { PublicIconUploader } from '@framework/utils/PublicIconUploader';
import { GroupOperation } from '@group/domain/GroupEntity/GroupOperation';
import { toast } from 'react-hot-toast';
import { ImageCropModal } from '@framework/ui/elements/ImageCropModal';
import { GroupConsoleTitle } from '../common';
import { GroupSetting } from '@group/domain/GroupEntity/GroupSetting';

type Props = {
    group: GroupEntity;
};

export const GroupConsolePage: React.FC<Props> = ({ group }: Props) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [iconUrl, setIconUrl] = useState<string | null>(group.imageUrl);
    const [name, setName] = useState(group.name.toString());
    const [iconImage, setIconImage] = useState<File | null>(null);
    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);
    const [signUpMethods, setSignUpMethods] = useState(group.setting.availableSignUpMethods);
    const [analysisSetting, setAnalysisSetting] = useState(group.setting.analysisSetting);
    const [license] = usePersistedObject(GroupLicense, RTDBPath.Group.groupLicensePath(group.id));

    const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const handleUploadImage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) setUploadedImage(event.target.files[0]);
    }, []);

    const handleImageClear = useCallback(() => {
        setIconImage(null);
        setIconUrl(null);
    }, []);

    const handleChangeSignUpMethods = useCallback((signUpMethods: GroupMemberSignUpMethod) => {
        setSignUpMethods(signUpMethods);
    }, []);

    const handleChangeAnalysisSetting = useCallback((analysisSetting: GroupAnalysisSetting) => {
        setAnalysisSetting(analysisSetting);
    }, []);

    const handleSubmit = async () => {
        setProcessing(true);

        const newIconUrl = iconImage ? await PublicIconUploader.uploadGroupIcon(group.id, iconImage) : iconUrl;
        setIconUrl(newIconUrl);

        if (await GroupOperation.update(group.id, name, new GroupSetting(signUpMethods, analysisSetting), newIconUrl)) {
            toast.success('グループ情報を変更しました。');
        } else {
            toast.error('グループ情報の変更に失敗しました。');
        }

        setProcessing(false);
    };

    const handleCropModalClose = useCallback(() => {
        (document.getElementById('icon-image') as HTMLInputElement).value = '';
        setUploadedImage(null);
        setIsCropModalOpen(false);
    }, []);

    const handleCropModalSubmit = useCallback((image: File) => {
        setIconImage(image);
        setIconUrl(URL.createObjectURL(image));
        setIsCropModalOpen(false);
    }, []);

    useEffect(() => {
        if (uploadedImage) setIsCropModalOpen(true);
    }, [uploadedImage]);

    return (
        <div className="pb-8">
            <div className="mb-4 flex items-center justify-between">
                <GroupConsoleTitle title="グループ設定" />
                <Button color="brand" onClick={handleSubmit} disabled={processing}>
                    更新する
                </Button>
            </div>
            {/* アイコン & グループ名 */}
            <div className="mb-4 grow">
                <div className="flex">
                    {iconUrl ? (
                        <div className="relative mr-4 size-16 shrink-0">
                            <div className="absolute size-16 overflow-hidden rounded-full">
                                <img className="size-full" src={iconUrl} alt={group.name.toString()} />
                            </div>
                            <div className="absolute flex size-16 items-start justify-end">
                                <div
                                    className="relative flex cursor-pointer items-center justify-center"
                                    onClick={handleImageClear}
                                >
                                    <FontAwesomeIcon icon={faCircle} className="absolute text-2xl text-red-500" />
                                    <FontAwesomeIcon icon={faTimes} className="absolute text-xl text-white" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="relative mr-4 size-16 shrink-0 overflow-hidden rounded-full">
                            <div className="absolute size-full opacity-50">
                                {generateDefaultIcon({
                                    id: group.id,
                                    name: name || group.name.toString(),
                                    size: 64,
                                })}
                            </div>
                            <div className="absolute flex size-full items-center justify-center">
                                <FontAwesomeIcon icon={faPlus} className="text-2xl text-blue-500" />
                            </div>
                            <input
                                type="file"
                                id="icon-image"
                                className="absolute size-full cursor-pointer opacity-0"
                                accept="image/png, image/jpeg"
                                onChange={handleUploadImage}
                            />
                        </div>
                    )}
                    <div className="flex w-7/12 flex-col">
                        <div className="font-bold">名前</div>
                        <input
                            type="text"
                            className="rounded-sm border p-2"
                            value={name}
                            onChange={handleChangeName}
                            autoFocus
                        />
                    </div>
                </div>
            </div>

            {/* ログイン方法 */}
            <div className="pb-2 pt-8 font-bold">新規ユーザ登録時に利用可能なログイン方法</div>
            <AvailableSignUpMethodForm signUpMethod={signUpMethods} onChange={handleChangeSignUpMethods} />

            {/* 分析機能 */}
            <div className="pb-2 pt-8 font-bold">分析機能の使用</div>
            <AnalysisSettingForm analysisSetting={analysisSetting} onChange={handleChangeAnalysisSetting} />

            {license && (
                <>
                    <div className="pb-2 pt-8 font-bold">ライセンスの情報</div>
                    <GroupLicenseInfo license={license} />
                </>
            )}

            {uploadedImage && (
                <ImageCropModal
                    isOpen={isCropModalOpen}
                    onClose={handleCropModalClose}
                    onSubmit={handleCropModalSubmit}
                    src={uploadedImage}
                />
            )}
        </div>
    );
};
