import { ThemeColor } from '@view-model/models/common/color';

type ViewColors = {
    fillColor: string;
    titleBarColor: string;
};

const ViewColorDefinitions: Record<ThemeColor, ViewColors> = {
    [ThemeColor.White]: {
        fillColor: '#FFFFFF',
        titleBarColor: '#0097A7',
    },
    [ThemeColor.Red]: {
        fillColor: '#FFCDD2',
        titleBarColor: '#EF9A9A',
    },
    [ThemeColor.Green]: {
        fillColor: '#E8F5E9',
        titleBarColor: '#C8E6C9',
    },
    [ThemeColor.Blue]: {
        fillColor: '#BBDEFB',
        titleBarColor: '#90CAF9',
    },
    [ThemeColor.Orange]: {
        fillColor: '#FFE0B2',
        titleBarColor: '#FFCC80',
    },
    [ThemeColor.Yellow]: {
        fillColor: '#FFF9C4',
        titleBarColor: '#FFF59D',
    },
    [ThemeColor.YellowGreen]: {
        fillColor: '#F0F4C3',
        titleBarColor: '#E6EE9C',
    },
    [ThemeColor.Purple]: {
        fillColor: '#F3E5F5',
        titleBarColor: '#E1BEE7',
    },
} as const;

export namespace ViewColor {
    export function getFillColor(color: ThemeColor): string {
        return ViewColorDefinitions[color]['fillColor'];
    }

    export function getTitleBarColor(color: ThemeColor): string {
        return ViewColorDefinitions[color]['titleBarColor'];
    }
}
