import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler } from 'react';
import { classNames } from '@framework/utils';
import { ThemeColor } from '@view-model/models/common/color';

type Props = {
    onClick: MouseEventHandler<HTMLDivElement>;
    icon: IconDefinition;
    tooltip?: string;
    fontSize: number;
    buttonSize: number;
    viewThemeColor: ThemeColor;
};

export const IconButton: React.FC<Props> = ({
    onClick,
    icon,
    tooltip,
    fontSize,
    buttonSize,
    viewThemeColor,
}: Props) => {
    const button = (
        <div
            className={classNames(
                'flex size-full cursor-pointer items-center justify-center rounded-lg font-bold hover:bg-black/10',
                viewThemeColor === ThemeColor.White ? 'text-white' : ''
            )}
        >
            <FontAwesomeIcon icon={icon} style={{ fontSize }} />
        </div>
    );

    return (
        <div onClick={onClick} style={{ width: buttonSize, height: buttonSize, pointerEvents: 'auto' }}>
            {tooltip ? (
                <DarkTooltip className="size-full" placement="bottom" tooltip={tooltip}>
                    {button}
                </DarkTooltip>
            ) : (
                button
            )}
        </div>
    );
};
