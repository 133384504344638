import { ColorMenuItem } from '@model-framework/color/ColorMenu';

export enum ThemeColor {
    White = 'White',
    Red = 'Red',
    Green = 'Green',
    Blue = 'Blue',
    Orange = 'Orange',
    Yellow = 'Yellow',
    YellowGreen = 'YellowGreen',
    Purple = 'Purple',
}

const ColorCodeOf: { [key in ThemeColor]: string } = {
    [ThemeColor.White]: '#FAFAFA',
    [ThemeColor.Red]: '#EF9A9A',
    [ThemeColor.Green]: '#C8E6C9',
    [ThemeColor.Blue]: '#90CAF9',
    [ThemeColor.Orange]: '#FFCC80',
    [ThemeColor.Yellow]: '#FFF59D',
    [ThemeColor.YellowGreen]: '#E6EE9C',
    [ThemeColor.Purple]: '#E1BEE7',
} as const;

const ColorNameOf: { [key in ThemeColor]: string } = {
    [ThemeColor.White]: '白',
    [ThemeColor.Red]: '赤',
    [ThemeColor.Green]: '緑',
    [ThemeColor.Blue]: '青',
    [ThemeColor.Orange]: '橙',
    [ThemeColor.Yellow]: '黄',
    [ThemeColor.YellowGreen]: '黄緑',
    [ThemeColor.Purple]: '紫',
} as const;

// 漏れなくThemeColorListを列挙するための中間データ
// （enumに新しい色を追加したり、削除した場合にこちらも編集しないとコンパイルエラーになる仕組み）
// 冗長なので他にいいやり方あれば直した方がよい。
const ThemeColorMap: { [key in ThemeColor]: ThemeColor } = {
    [ThemeColor.White]: ThemeColor.White,
    [ThemeColor.Red]: ThemeColor.Red,
    [ThemeColor.Green]: ThemeColor.Green,
    [ThemeColor.Blue]: ThemeColor.Blue,
    [ThemeColor.Orange]: ThemeColor.Orange,
    [ThemeColor.Yellow]: ThemeColor.Yellow,
    [ThemeColor.YellowGreen]: ThemeColor.YellowGreen,
    [ThemeColor.Purple]: ThemeColor.Purple,
} as const;

const ThemeColorList = Object.values(ThemeColorMap);

export namespace ThemeColor {
    export function toColorCode(themeColor: ThemeColor): string {
        return ColorCodeOf[themeColor];
    }

    export function toColorName(themeColor: ThemeColor): string {
        return ColorNameOf[themeColor];
    }

    export function values(): Readonly<ThemeColor[]> {
        return ThemeColorList;
    }

    export function menuItems(): Readonly<ColorMenuItem[]> {
        return ThemeColorList.map((color) => ({
            colorName: color,
            fillColor: toColorCode(color),
        }));
    }
}

export function isThemeColor(colorStr: string): colorStr is ThemeColor {
    return ThemeColorList.some((color) => colorStr === color);
}
