import { ViewName, ViewSetting } from '@view-model/domain/view';
import { CommandManager, CompositeCommand, ICommand, UpdateCommand } from '@model-framework/command';
import { ObjectRepository, RTDBPath, TextRepository } from '@framework/repository';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { PositionSetRepository } from '@view-model/models/common/PositionSet';
import { StickyModelContents } from '@view-model/domain/model';
import { PointJSON } from '@schema-common/view-model';
import { ViewRepository } from '@view-model/infrastructure/view-model/ViewRepository';
import { StickyModelElementPositionMapRepository } from '@view-model/infrastructure/basic-model/StickyModelElementPositionMapRepository';
import { ModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ModelCascadeRepository';
import { viewGridLayout } from '@view-model/ui/components/View/constants';
import { ModelId, ViewId, ViewModelId } from '@schema-common/base';
import { ThemeColor } from '@view-model/models/common/color';

export class ViewOperation {
    private readonly repository: ViewRepository;
    private readonly viewPositionRepository: ObjectRepository<PointJSON, Point>;

    public constructor(
        private readonly viewModelId: ViewModelId,
        private readonly viewId: ViewId,
        private readonly modelId: ModelId,
        private readonly commandManager: CommandManager
    ) {
        this.repository = new ViewRepository(viewModelId);
        this.viewPositionRepository = new ObjectRepository(Point, RTDBPath.View.positionPath(this.viewModelId, viewId));
    }

    /**
     * ビュー名の編集中（未確定）のテキスト内容をハンドルします。
     * @param name 編集中（未確定）のテキスト
     */
    async handleEditingNameChanged(name: ViewName): Promise<void> {
        const { viewModelId, viewId } = this;
        const nameRepository = new TextRepository(RTDBPath.View.namePath(viewModelId, viewId));

        await nameRepository.save(name.value);
    }

    /**
     * ビュー名の編集確定時のテキスト内容をハンドルします。
     * 確定時のテキスト内容が編集前と比較して変化していた場合はUndoヒストリに登録されます。
     *
     * @param name 編集確定後のテキスト
     * @param previousName 編集前のテキスト
     */
    async handleEditingNameConfirmed(name: ViewName, previousName: ViewName): Promise<void> {
        const trimmedName = name.trim();
        if (trimmedName.isEquals(previousName)) {
            return;
        }
        const { viewModelId, viewId } = this;

        const command = new UpdateCommand(
            previousName.value,
            name.value,
            new TextRepository(RTDBPath.View.namePath(viewModelId, viewId))
        );
        this.commandManager.execute(command);
    }

    async handleChangingViewColorConfirmed(color: ThemeColor, previousColor: ThemeColor): Promise<void> {
        const { viewModelId, viewId } = this;

        const viewColorUpdateCommand = new UpdateCommand(
            previousColor,
            color,
            new TextRepository(RTDBPath.View.themeColorPath(viewModelId, viewId))
        );

        this.commandManager.execute(viewColorUpdateCommand);
    }

    async handleUpdatingPositionChanged(position: Point): Promise<void> {
        return this.viewPositionRepository.save(position);
    }

    async handleUpdatingPositionConfirmed(position: Point, previousPosition: Point): Promise<void> {
        const snappedPosition = position.snapTo(viewGridLayout);
        const viewPositionUpdateCommand = new UpdateCommand(
            previousPosition,
            snappedPosition,
            this.viewPositionRepository
        );

        // スナップ後の座標が移動前と変化しない場合は、コマンドをただ実行する（Undoスタックに積まない）
        if (snappedPosition.isEqual(previousPosition)) {
            viewPositionUpdateCommand.do();
        } else {
            this.commandManager.execute(viewPositionUpdateCommand);
        }
    }

    async handleResizingConfirmed(
        newViewRect: Rect,
        oldViewRect: Rect,
        callback: (snappedViewRect: Rect) => void
    ): Promise<void> {
        const { viewModelId, modelId } = this;
        const modelContents = await new ModelCascadeRepository(viewModelId).load(modelId);
        if (!modelContents) return;

        const snappedViewRect = newViewRect.snapTo(viewGridLayout);
        const compositeCommand = this.buildResizeCommand(snappedViewRect, oldViewRect, modelContents);

        // スナップ後の座標・サイズが変化していない場合はUndoスタックに積まずにコマンドを実行する
        if (snappedViewRect.isEqual(oldViewRect)) {
            compositeCommand.do();
        } else {
            this.commandManager.execute(compositeCommand);
        }

        callback(snappedViewRect);
    }

    private buildResizeCommand(
        viewRect: Rect,
        previousViewRect: Rect,
        modelContents: StickyModelContents
    ): CompositeCommand {
        const { viewModelId, viewId } = this;
        const sizeUpdateCommand = new UpdateCommand(
            previousViewRect.getSize(),
            viewRect.getSize(),
            new ObjectRepository(Size, RTDBPath.View.sizePath(viewModelId, viewId))
        );

        const viewPositionUpdateCommand = new UpdateCommand(
            previousViewRect.topLeft(),
            viewRect.topLeft(),
            this.viewPositionRepository
        );

        if (modelContents instanceof StickyModelContents) {
            // 付箋モデルだけの特別対応。
            // モデル要素の位置はビューのサイズ変更に関わらず画面上の現在表示位置を維持するように、ビューのサイズ変更に合わせて移動する
            // （モデル要素の位置はビューの左上を原点とした相対座標のため、移動しないとビューの左上にくっつくように画面上は移動して見える）
            const contentsOffset = previousViewRect.topLeft().subtract(viewRect.topLeft());
            const moveContentsCommand = this.buildMoveContentsCommand(modelContents, contentsOffset);

            if (moveContentsCommand) {
                return new CompositeCommand(sizeUpdateCommand, viewPositionUpdateCommand, moveContentsCommand);
            }
        }

        return new CompositeCommand(sizeUpdateCommand, viewPositionUpdateCommand);
    }

    private buildMoveContentsCommand(modelContents: StickyModelContents, contentsOffset: Point): ICommand | null {
        const { viewModelId, modelId } = this;

        if (contentsOffset.x === 0 && contentsOffset.y === 0) return null;

        // TODO: StickyModelの要素がViewに入り込んでいるのでその整理
        //
        // クリティカルな問題の修正のため一時的にこの場所でStickyModel関連のリポジトリを取得して利用している
        // （実装はStickyModelContentsOperation から拝借）
        // https://github.com/levii/balus-app/issues/2526
        const positionMapRepository = new StickyModelElementPositionMapRepository(viewModelId, modelId);

        const commentPositionSetRepository = new PositionSetRepository(
            RTDBPath.Comment.positionsPath(viewModelId, modelId)
        );
        const descriptionPanelPositionSetRepository = new PositionSetRepository(
            RTDBPath.DescriptionPanel.positionsPath(viewModelId, modelId)
        );

        return modelContents.buildMoveCommand(
            contentsOffset.x,
            contentsOffset.y,
            positionMapRepository,
            commentPositionSetRepository,
            descriptionPanelPositionSetRepository
        );
    }

    handleSettingChanged(setting: ViewSetting): Promise<ViewSetting> {
        return this.repository.saveSetting(this.viewId, setting);
    }
}
