import { ThemeColor } from '@view-model/models/common/color';
import { useCallback } from 'react';

type Props = {
    onSelectColor(color: ThemeColor): void;
    color: ThemeColor;
};

export const ColorButton: React.FC<Props> = ({ onSelectColor, color }: Props) => {
    const handleClick = useCallback(() => {
        onSelectColor(color);
    }, [onSelectColor, color]);

    return (
        <button className="flex w-full py-6 pl-8 pr-12 hover:bg-gray-300" onClick={handleClick}>
            <span className="mr-8 size-16 flex-none" style={{ backgroundColor: ThemeColor.toColorCode(color) }} />
            <span className="flex-none">{ThemeColor.toColorName(color)}</span>
        </button>
    );
};
