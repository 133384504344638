import { classNames } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePopupRef } from '@framework/hooks';
import { faAngleRight, faFillDrip } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { ThemeColor } from '@view-model/models/common/color';
import { ColorButton } from './ColorButton';

type Props = {
    onChangeColor(color: ThemeColor): void;
};

export const ColorMenuButton: React.FC<Props> = ({ onChangeColor }: Props) => {
    const { isOpen, popupRef, handleToggle } = usePopupRef<HTMLDivElement>(false);

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            handleToggle();
        },
        [handleToggle]
    );

    const handleChangeColor = useCallback(
        (color: ThemeColor) => {
            onChangeColor(color);
        },
        [onChangeColor]
    );

    return (
        <div className="relative hover:bg-gray-200">
            <button
                className={classNames('flex w-full cursor-pointer px-8 py-4 text-gray-700 focus:outline-none')}
                onClick={handleClick}
            >
                <div className="fa-fw inline-block w-16 rounded">
                    <FontAwesomeIcon icon={faFillDrip} />
                </div>
                <div className="inline-block pl-4">カラー | Color</div>
                <FontAwesomeIcon icon={faAngleRight} className="ml-auto" />
            </button>
            <div
                className={classNames(
                    'absolute -right-72 top-0 z-50 w-72 rounded-lg bg-white py-8 shadow-2xl',
                    isOpen ? 'block' : 'hidden'
                )}
                ref={popupRef}
            >
                <ColorButton onSelectColor={handleChangeColor} color={ThemeColor.White} />
                <ColorButton onSelectColor={handleChangeColor} color={ThemeColor.Red} />
                <ColorButton onSelectColor={handleChangeColor} color={ThemeColor.Green} />
                <ColorButton onSelectColor={handleChangeColor} color={ThemeColor.Blue} />
                <ColorButton onSelectColor={handleChangeColor} color={ThemeColor.Orange} />
                <ColorButton onSelectColor={handleChangeColor} color={ThemeColor.Yellow} />
                <ColorButton onSelectColor={handleChangeColor} color={ThemeColor.YellowGreen} />
                <ColorButton onSelectColor={handleChangeColor} color={ThemeColor.Purple} />
            </div>
        </div>
    );
};
